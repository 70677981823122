export default {
	title: 'Register for Flourish',
	bg: {
		color: false,
		image:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Logomark/logo_opacity.png',
		size: '1000px 1000px',
		position: '-190px -375px',
	},
	projectForm: {
		fields: [
			[
				{
					label: 'Project Name',
					name: 'name',
					placeholder: "Girl's School in Kenya",
					field: 'default',
					type: 'text',
					maxWidth: '33.33%',
					required: true,
					style: {
						background: 'inherit',
					},
				},
				{
					label: 'Goal',
					name: 'donates_goal',
					placeholder: "10000",
					field: 'default',
					type: 'number',
					maxWidth: '33.33%',
					style: {
						background: 'inherit',
					},
				},
				{
					label: 'End Date',
					name: 'end_date',
					placeholder: "11-08-2018",
					field: 'default',
					type: 'text',
					maxWidth: '33.33%',
					style: {
						background: 'inherit',
					},
				},
			],
			[
				{
					label: 'Project Description',
					name: 'description',
					placeholder: 'Description',
					field: 'textarea',
					columns: '40',
					rows: '2',
					type: 'email',
					maxWidth: '100%',
					style: {
						background: 'inherit',
						minHeight: '5em'
					},
				},
			],
		],
		submitField: {
			text: 'Save Project',
		},
	},
}
