import {
	GET_INVOICES_SUCCESS,
	ACCOUNTING_ERROR,
	ACCOUNTING_PENDING,
	MONEY_REQUEST_SUCCESS,
	REQUEST_ERROR
} from '../factory/types'

const initialState = {
	invoices: [],
	error: '',
	pending: false,
	amount: 0,
	request_error: ''
}

/**
 * Reducer which handles all events related to flashing error and notification messages
 */
const messageReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
	case GET_INVOICES_SUCCESS:
		return {
			...state,
			invoices: action.payload,
			pending: false
		}
	case ACCOUNTING_PENDING:
		return {
			...state,
			pending: true,
			error: ''
		}
	case ACCOUNTING_ERROR:
		return {
			...state,
			pending: false,
			error: action.payload
		}
	case MONEY_REQUEST_SUCCESS:
		return {
			...state,
			pending: false,
			amount: action.payload
		}
	case REQUEST_ERROR:
		return {
			...state,
			request_error: action.payload,
			pending: false
		}
	default:
		return {
			...state
		}
	}
}

export default messageReducer
