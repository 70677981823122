export default {
	title: 'Organization Onboarding Checklist',
	subtitle: 'To help you get on your feet',
	checklist: [
		{
			title: 'Step 1.',
			link: 'https://flourishchange.typeform.com/to/WlB1t1',
			text: 'Please fill out this typeform to begin your #flourishing campaign.',
			id: 1,
			completed: false
		},
		{
			title: 'Step 2.',
			link: '/',
			text: 'Download the templates for custom marketing materials, click here.',
			id: 2,
			completed: false
		},
		{
			title: 'Step 3.',
			link: 'https://hello.flourishchange.com/meetings/zach120',
			text: 'Meet with us! We want to check in and make sure you are #flourishing. (Click here)',
			id: 3,
			completed: false
		},
		{
			title: 'Step 4.',
			link: '/admin/donors',
			text: 'Gain your first 10 donors.',
			id: 4,
			completed: false
		},
		{
			title: 'Step 5.',
			text: 'Identify your next circle of potential donors.',
			id: 5,
			completed: false
		},
		{
			title: 'Step 6.',
			link: 'https://hello.flourishchange.com/meetings/zach120',
			text: 'How are you? We really want to know - meet with us for an update, please!',
			id: 6,
			completed: false
		}
	]
}
