import {
	MESSAGE_REQUEST,
	UPDATE_MESSAGE_SUCCESS,
	UPDATE_MESSAGE_FAIL,
	GET_MESSAGES_SUCCESS,
	TEST_SEND_SUCCESS,
	TEST_SEND_FAIL,
} from '../factory'

const initialState = {
	messages: [],
	pending: false,
	error: ''
}
/**
 * Reducer which handles all events related to flashing error and notification messages
 */
const onboardingReducer = (state = initialState, action) => {
	switch (action.type) {
	// Flash an error
	case MESSAGE_REQUEST: {
		return {...state, pending: true, error: ''}
	}
	case TEST_SEND_SUCCESS:
	case UPDATE_MESSAGE_SUCCESS: {
		return {...state, pending: false}
	}
	case GET_MESSAGES_SUCCESS: {
		return {...state, messages: action.payload, pending: false}
	}
	case TEST_SEND_FAIL:
	case UPDATE_MESSAGE_FAIL: {
		return {...state, error: action.payload, pending: false}
	}
	default:
		return state
	}
}

export default onboardingReducer
