export default {
	title: 'Login to Flourish',
	bg: {
		color: false,
		image:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Logomark/logo_opacity.png',
		size: '1000px 1000px',
		position: '-190px -375px',
	},
	loginForm: {
		fields: [
			[
				{
					label: 'Email',
					name: 'email',
					placeholder: 'demo@flourishchange.com',
					field: 'default',
					type: 'email',
					maxWidth: '100%',
					style: {
						background: 'inherit',
					},
				},
			],
			[
				{
					label: 'Password',
					name: 'password',
					placeholder: '••••••••••••',
					field: 'default',
					type: 'password',
					maxWidth: '100%',
					style: {
						height: 28,
						background: 'inherit',
					},
				},
			],
		],
		submitField: {
			text: 'Log In',
		},
	},
	register: {
		text: 'Register',
		link: '/register',
	},
	forgot: {
		text: 'Forgot Password',
		link: '/forgot',
	},
}
