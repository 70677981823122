export default {
	title: 'Reset Your Password',
	bg: {
		color: false,
		image:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Logomark/logo_opacity.png',
		size: '1000px 1000px',
		position: '-190px -375px',
	},
	resetForm: {
		fields: [
			[
				{
					label: 'Password',
					name: 'password',
					placeholder: '••••••••••••',
					field: 'default',
					type: 'password',
					maxWidth: '100%',
					style: {
						height: 28,
						background: 'inherit',
					},
				},
			],
			[
				{
					label: 'Confirm Password',
					name: 'password_confirm',
					placeholder: '••••••••••••',
					field: 'default',
					type: 'password',
					maxWidth: '100%',
					style: {
						height: 28,
						background: 'inherit',
					},
				},
			],
		],
		submitField: {
			text: 'Reset Password',
		},
	},
	login: {
		text: 'Sign In',
		link: 'login',
	},
}
