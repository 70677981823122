export default {
	title: 'No Sweat, this happens',
	subtitle: "Give us your account email and we'll get you a new password",
	bg: {
		color: false,
		image:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Logomark/logo_opacity.png',
		size: '1000px 1000px',
		position: '-190px -375px',
	},
	forgotForm: {
		fields: [
			[
				{
					label: 'Email',
					name: 'email',
					placeholder: 'demo@flourishchange.com',
					field: 'default',
					type: 'text',
					maxWidth: '100%',
					style: {
						background: 'inherit',
					},
				},
			],
		],
		submitField: {
			text: 'Reset My Password',
		},
	},
	login: {
		text: 'Sign In',
		link: 'login',
	},
}
