import axios from 'axios'

import {API, TEST_HEADER, GET_INVOICES_SUCCESS, ACCOUNTING_PENDING,
	ACCOUNTING_ERROR, MONEY_REQUEST_SUCCESS, REQUEST_ERROR} from './types'

import {getError} from './error'

export const getInvoices = (token) => (dispatch) => {
	dispatch({type: ACCOUNTING_PENDING})
	axios.get(`${API}/v1/mobile/updated/invoices/get_admin_invoices`, {
		headers: {
			method: 'GET',
			authorization: token,
			'flourish-test-header': TEST_HEADER
		}
	}).then((res) => {
		dispatch({payload: res.data, type: GET_INVOICES_SUCCESS})
	}
	).catch((e) => {
		dispatch({type: ACCOUNTING_ERROR,
			payload: getError(e) || 'Error Fetching Invoices'
		})
	})
}

export const requestMoney = (org, admin, amount) => (dispatch) => {
	dispatch({type: ACCOUNTING_PENDING})
	const text = `${org} requests ${amount}. Requested by ${admin.first_name} ${admin.last_name}, ${admin.email}, ${admin.phone}.`
	axios.post(`${process.env.slack_webhook}`, `{"text":"${text}"}`,
		{headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}} )
		.then(()=>{
			console.log('SUCCESS')
			dispatch({type: MONEY_REQUEST_SUCCESS, payload: parseFloat(amount.replace('$', ''))})
		})
		.catch(()=>dispatch({type: REQUEST_ERROR,
			payload: 'Error Requesting Donations. Please try again or email support@flourishchange.com to request your doantions.'
		}))
}
