import {persistCombineReducers} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './authReducer'
import adminReducer from './adminReducer'
import statisticsReducer from './statisticsReducer'
import donateNowReducer from './donateNowReducer'
import notificationReducer from './notificationReducer'
import userReducer from './userReducer'
import subscriptionReducer from './subscriptionReducer'
import onboardingReducer from './onboardingReducer'
import accountingReducer from './accountingReducer'
import oneClickReducer from './oneClickReducer'

const config = {
	key: 'rootAdmin',
	storage,
	timeout: null,
}

const rootReducer = persistCombineReducers(config, {
	authState: authReducer,
	adminState: adminReducer,
	donateNowState: donateNowReducer,
	statistics: statisticsReducer,
	userState: userReducer,
	notificationState: notificationReducer,
	subscriptionState: subscriptionReducer,
	onboardingState: onboardingReducer,
	accountingState: accountingReducer,
	oneClickState: oneClickReducer
})

export default rootReducer
