import colors from './colors'
import customMessages from './customMessageEnums'

import {
	webTopBar,
	webFooter,
} from './layouts'

import {
	home,
	newOrganization,
	organizations,
	add,
	login,
	register,
	forgot,
	reset,
	mobileDonationFlow,
	project,
	onboarding
} from './pages'

const theme = {
	publicPaths: ['/login', '/register', '/forgot', '/reset', '/donations/mobile', '/unsubscribe'],
	defaultRedirect: '/login',
	colors,
	layoutsContent: {
		webTopBar,
		webFooter,
	},
	pagesContent: {
		home,
		newOrganization,
		organizations,
		add,
		login,
		register,
		forgot,
		reset,
		mobileDonationFlow,
		project,
		onboarding
	},
	customMessages,
	images: {
		logo:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/LogoPNGs/RGB/Wordmark/MarigoldType.png',
	},
	fonts: {
		primary: "'Work Sans', sans-serif",
		secondary: "'Frank Ruhl Libre', serif",
	},
	adminSidebar: {
		options: [
			{
				faIcon: 'fas fa-home',
				text: 'My Dashboard',
				link: '/admin',
			},
			{
				faIcon: 'fas fa-list-ul',
				text: 'My Projects',
				link: '/admin/projects',
			},
			{
				faIcon: 'fas fa-users',
				text: 'My Donors',
				link: '/admin/donors',
				subItems: [
					{text: 'My Users', link: '/users'}
				]
			},
			{
				faIcon: 'fas fa-file-invoice-dollar',
				text: 'My Donations',
				link: '/admin/donations',
				subItems: [
					{text: 'My Receipts', link: '/receipts'}
				]
			},
			{
				faIcon: 'fas fa-envelope',
				text: 'One Click Relationships',
				link: '/admin/OneClickRelationships',
				subItems: [
					{text: 'My Custom Messages', link: '/customMessages'}
				]
			}
		],
	},
	body: {
		header: {
			breadcrumbs: [
				{
					title: 'Admin',
					path: '/',
				},
				{
					title: '/ Our organizations',
					path: '/organizations',
				},
				{
					title: '/ New organization',
					path: '/organizations/new',
				},
				{
					title: '/ One click relationships',
					path: '/messages',
				},
				{
					title: '/ Add admin',
					path: '/add',
				},
				{
					title: '/ My Projects',
					path: '/admin/projects',
				},
				{
					title: '/ My Projects',
					path: '/admin/project/'
				},
				{
					title: '/ To-Do List',
					path: '/admin/checklist/'
				}
			],
			profile: {
				icon: 'far fa-user fa-2x',
			},
			logout: {
				text: 'Logout',
				link: '/logout',
			},
			organization: {
				text: 'My Organization',
				link: '/admin/organization/',
			},
			onboarding: {
				text: "My Checklist",
				link: '/admin/checklist/'
			}
		},
	},
	footer: {
		menu: [
			{
				text: 'Home',
				link: '/',
			},
			{
				text: 'Nonprofits',
				link: '/nonprofits/',
			},
			{
				text: 'Google Ad Grant',
				link: '/marekting/',
			},
			{
				text: 'Privacy Policy',
				link: '/privacy/',
			},
			{
				text: 'Terms of Use',
				link: '/terms-and-conditions/',
			},
		],
		appleStore: {
			image:
				'https://flourishfiles.blob.core.windows.net/files/Marketing/AppBadges/app-store.svg',
			link:
				'https://itunes.apple.com/us/app/flourish-donations/id1356801492?mt=8',
		},
		playStore: {
			image:
				'https://flourishfiles.blob.core.windows.net/files/Marketing/AppBadges/google-play.png',
			link:
				'https://play.google.com/store/apps/details?id=com.flourish.flourish&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
		},
		copyRight:
			'© 2018 Copyright <strong>Flourish</strong>. All Rights Reserved',
		madeBy: 'Made with ♥ by The Flourish Team in ATX',
	},
}

export default theme
