import 'es6-promise'
import 'isomorphic-fetch'
import React from 'react'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import {isAuthenticated, getUserInfo, isBrowser} from './src/util/auth'

import createStore from './src/state/createStore'

import routeValidate from './src/util/routeValidate'

const store = createStore()

persistStore(store, null, () => store.getState())

const persistor = persistStore(store)

// eslint-disable-next-line react/display-name,react/prop-types
export const wrapRootElement = ({element}) => {
	return (
		<Provider store={store} persistor={persistor}>
			{element}
		</Provider>
	)
}

export const onClientEntry  = () => {
	routeValidate()
}


export const onRouteUpdate = () => {
	const profile = getUserInfo()

	if (isAuthenticated() && isBrowser) {
		window.analytics && window.analytics.page()
		window.analytics && window.analytics.identify(`${profile.uid}`, {
			admin: true,
			email: profile.user_email,
			name: profile.userName,
			username: profile.user_email
		})
	}
}