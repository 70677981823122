import axios from 'axios'

import {
	MESSAGE_REQUEST,
	UPDATE_MESSAGE_SUCCESS,
	UPDATE_MESSAGE_FAIL,
	GET_MESSAGES_SUCCESS,
	TEST_SEND_SUCCESS,
	TEST_SEND_FAIL,
	API,
	TEST_HEADER,
} from './types'

export const updateMessage = (data, token, successCallBack, errorCallBack) => (dispatch) => {
	dispatch({type: MESSAGE_REQUEST})

	axios.post(`${API}/v1/mobile/updated/custom_template/add_or_update`, data,
		{
			'headers': {
				'authorization': token,
				"flourish-test-header": TEST_HEADER,
				'Content-Type': 'application/json',
			}
		})
		.then(() => {
			dispatch({type: UPDATE_MESSAGE_SUCCESS})
			successCallBack()
		})
		.catch(() => {
			dispatch({type: UPDATE_MESSAGE_FAIL, payload: "We couldn't update your message. Please try again." })
			errorCallBack()
		})
}

export const getMessages = (token) => (dispatch) => {
	dispatch({type: MESSAGE_REQUEST})

	axios.get(`${API}/v1/mobile/updated/custom_template/get`,
		{
			'headers': {
				'authorization': token,
				"flourish-test-header": TEST_HEADER,
				'Content-Type': 'application/json',
			}
		})
		.then((res) => {
			const messages = res.data
			dispatch({type: GET_MESSAGES_SUCCESS, payload: messages })
		})
		.catch(() => {
			dispatch({type: TEST_SEND_FAIL, payload: "We couldn't send your test. Please try again." })
		})
}

export const sendTest = (data, token, successCallBack, errorCallBack) => (dispatch) => {
	dispatch({type: MESSAGE_REQUEST})
	console.log(errorCallBack)
	axios.post(`${API}/v1/mobile/updated/custom_template/send_test`, data,
		{
			'headers': {
				'authorization': token,
				"flourish-test-header": TEST_HEADER,
				'Content-Type': 'application/json',
			}
		})
		.then(() => {
			dispatch({type: TEST_SEND_SUCCESS})
			successCallBack()
		})
		.catch(() => {
			dispatch({type: TEST_SEND_FAIL, payload: "We couldn't send your test. Please try again." })
			errorCallBack()
		})
}