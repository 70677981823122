import axios from 'axios'

import {getOrgById} from './'

import {
	START_SUBSCRIPTION_REQUEST,
	START_SUBSCRIPTION_SUCCESS,
	START_SUBSCRIPTION_FAIL,
	ERROR,
	API,
	PAYMENTSAPI,
	TEST_HEADER,
} from './types'


export const startSubscription = (data) => (dispatch) => {
	const {stripe, user, organization, access_token, legal} = data
	const {f_name, l_name, email} = user

	dispatch({type: START_SUBSCRIPTION_REQUEST})

	stripe.createToken({name: `${f_name} ${l_name}`, email})
		.then(({token}) => {
			const body = {
				description: `Customer for ${organization.title} created by ${email} on ${new Date()}`,
				email,
				orgId: organization.id,
				stripeToken: token.id,
				legal
			}
			axios.post(`${PAYMENTSAPI}/subscription/start/`, body)
				.then(() => {
					dispatch(getOrgById(organization.id, access_token, ()=>location.reload()))
					dispatch({type: START_SUBSCRIPTION_SUCCESS})
				})
				.catch((err) => dispatch({type: START_SUBSCRIPTION_FAIL}))
		})
		.catch((err) => dispatch({type: START_SUBSCRIPTION_FAIL}))
}
