
import { navigate } from '@reach/router'
import {
	isAuthenticated,
	isBrowser,
	getUserInfo,
} from './auth'

import theme from '../theme/content'

const pathName = typeof window !== 'undefined' && window.location.pathname.replace(/\/$/, '')

var redirecTo = theme.defaultRedirect

const publicPaths = theme.publicPaths

const routeValidate = () => {
	const profile = getUserInfo()

	if (profile.userRole) {
		if (!isAuthenticated() && isBrowser && !publicPaths.indexOf(pathName)) {
			redirecTo = 'login'

			if (profile.userRole !== 1) {
				redirecTo = '/login'
			}

			if (profile.userRole !== 2 && !pathName.includes('admin')) {
				redirecTo = '/admin'
			}

			navigate(redirecTo)
		}

		if (isAuthenticated() && isBrowser) {
			redirecTo = null

			if (profile.userRole === 1 && !pathName.includes('admin') && !publicPaths.indexOf(pathName)) {
				redirecTo  = '/admin'
			}

			if (profile.userRole === 1 && pathName === '/') {
				redirecTo  = '/admin'
			}

			navigate(redirecTo)
		}
	} else {
		if (publicPaths.indexOf(pathName) === -1) {
			navigate('/login')
		}
	}
}

export default routeValidate
