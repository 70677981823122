import axios from 'axios'
import {getError} from './error'

import {
	GET_ORGS_REQUEST,
	GET_ORGS_SUCCESS,
	GET_ORGS_FAILURE,
	GET_PROJECTS_FAILURE,
	GET_PROJECTS_SUCCESS,
	GET_PROJECTS_REQUEST,
	SELECT_ORG,
	SELECT_PROJ,
	SELECT_PROJECTS,
	SET_ORG_REQUEST,
	SET_ORG_SUCCESS,
	SET_ORG_FAILURE,
	SET_PROJ_REQUEST,
	SET_PROJ_SUCCESS,
	SET_PROJ_FAILURE,
	CLEAR_ORG_AND_PROJ,
	CLEAR_PROJECTS,
	CLEAR_SELECTED_PROJ,
	CLEAR_DONATE_NOW,
	CLEAR_ERRORS,
	DONATE_NOW_SUCCESS,
	DONATE_NOW_REQUEST,
	DONATE_NOW_FAILURE,
	ONE_TIME_DONATION_AUTHED_FAILURE,
	ONE_TIME_DONATION_AUTHED_SUCCESS,
	ONE_TIME_DONATION_AUTHED_REQUEST,
	SET_DAILY_DONATION_REQUEST,
	SET_DAILY_DONATION_SUCCESS,
	SET_DAILY_DONATION_FAILURE,
	LINK_BANK_REQUEST,
	LINK_BANK_SUCCESS,
	LINK_BANK_FAILURE,
	LINK_CREDIT_CARD_REQUEST,
	LINK_CREDIT_CARD_SUCCESS,
	LINK_CREDIT_CARD_FAILURE,
	MESSAGE,
	API,
	TEST_HEADER,
} from './types'


/*
Actions to retrieve information from backend

// Action to get organizations
export function getOrgs() {
	return dispatch => {
		dispatch({
			type: GET_ORGS_REQUEST,
		});
		axios.get(`${API}/v1/organization/list_names`, {
				headers: {
					"flourish-test-header": TEST_HEADER
				}
			})
			// On success
			.then(res => {
				const organizations = res.data.data;
				dispatch({
					type: GET_ORGS_SUCCESS,
					organizations,
				});
			})
			// On failure
			.catch((e) => {
				dispatch({
					type: GET_ORGS_FAILURE,
					error: getError(e) || 'Error pulling organizations.'
				});
			});
	};
}

// Actions to handle donations

// Action to trigger a one-time donation
// TODO ensure this route is working
export function donateNow(name, email, donation, cardNum, cvcNum, zipcode, expMonth, expYear, orgId, projId) {
	return dispatch => {
		dispatch({
			type: DONATE_NOW_REQUEST,
		});
		const fullName = name.split(' ');
		const firstName = fullName[0];
		const lastName = fullName[fullName.length - 1];

		// Formatting date: adding a 0 to beginning of month
		// (if less than 10) and taking last two year digits
		// const expDate = ('0' + expMonth).slice(-2) + expYear.toString().slice(-2);

		// If a project has been selected, otherwise donate to
		// organization as a whole
		const card = {
			number: cardNum,
			exp_month: ('0' + expMonth).slice(-2),
			exp_year: expYear,
			cvc: cvcNum,
			address_zip: zipcode,
		};

		// Process the request via stripe
		stripe.createToken({
				card
			})
			.then(res => {
				const token = res.id;
				if (projId) {
					axios.post(`${API}/user/donate_project_no_auth`, {
							first_name: firstName,
							last_name: lastName,
							token,
							email,
							organization_id: orgId,
							project_id: projId,
							sum: donation,
						}, {
							headers: {
								"flourish-test-header": TEST_HEADER
							}
						})
						.then(() => {
							// On success
							dispatch({
								type: DONATE_NOW_SUCCESS,
							});
							dispatch({
								type: MESSAGE,
								message: 'Thanks for donating!'
							});
						})
						.catch(() => {
							// On failure
							dispatch({
								type: DONATE_NOW_FAILURE,
							});
						});
				} else {
					axios.post(`${API}/user/donate_organization_no_auth`, {
							first_name: firstName,
							last_name: lastName,
							token,
							email,
							organization_id: orgId,
							sum: donation,
						}, {
							headers: {
								"flourish-test-header": TEST_HEADER
							}
						})
						.then(() => {
							// On success
							dispatch({
								type: DONATE_NOW_SUCCESS,
							});
						})
						.catch(() => {
							// On failure
							dispatch({
								type: DONATE_NOW_FAILURE,
							});
						});
				}
			})
			.catch(() => {
				dispatch({
					type: DONATE_NOW_FAILURE,
				});
			});
	};
}

// First action to handle one time authorized donations
export function processOneTimeDonation(token, key, sum, projId, orgId) {
	return dispatch => {
		dispatch({
			type: ONE_TIME_DONATION_AUTHED_REQUEST,
		});
		exchangeToken(token, key)
			.then((bearer) => oneTimeDonationAuthorized(bearer, sum, projId, orgId))
			.catch((err) => {
				dispatch({
					error: getError(err) || 'Error donating.',
					type: ONE_TIME_DONATION_AUTHED_FAILURE,
				});
			});
	};
}

// Second action to handle one time authorized donations
export function oneTimeDonationAuthorized(bearer, sum, projId, orgId) {
	return dispatch => {
		dispatch({
			type: ONE_TIME_DONATION_AUTHED_REQUEST,
		});
		axios.post(`${API}/v1/donation/process_authorized`, {
				sum,
				organization_id: orgId,
				project_id: projId,
			}, {
				headers: {
					authorization: bearer,
					"flourish-test-header": TEST_HEADER
				}
			})
			// On success
			.then(() => {
				dispatch({
					type: ONE_TIME_DONATION_AUTHED_SUCCESS
				});
				dispatch({
					type: MESSAGE,
					message: 'Thanks for donating!',
				});
			})
			// On failure
			.catch((e) => {
				dispatch({
					error: getError(e) || 'Error donating.',
					type: ONE_TIME_DONATION_AUTHED_FAILURE,
				});
			});
	};
}
*/
// Action to authorize account during 1-time donations
function exchangeToken(token, key) {
	return axios.post(`${API}/v1/user/exchange_otp_token`, {
		otp_token: token,
		key
	}, {
		headers: {
			"flourish-test-header": TEST_HEADER
		}
	})
		.then((res) => {
			return res.data
		})
		.catch((err) => (dispatch) => {
			dispatch({
				error: getError(err) || 'Error Authorizing Account.',
				type: ONE_TIME_DONATION_AUTHED_FAILURE,
			})
		})
}
/*
// Actions that solely change redux state, no backend interaction

// Action to clear selected org and proj
export function clearOrgAndProj() {
	return {
		type: CLEAR_ORG_AND_PROJ,
	};
}

// Action to clear projects
export function clearProjects() {
	return {
		type: CLEAR_PROJECTS,
	};
}

// Action to clear the selected project
export function clearSelectedProj() {
	return {
		type: CLEAR_SELECTED_PROJ,
	};
}

// Action to clear entire donate now state
export function clearDonateNow() {
	return {
		type: CLEAR_DONATE_NOW,
	};
}

// Action to clear errors from redux state
export function clearErrors() {
	return {
		type: CLEAR_ERRORS,
	};
}

*/

/* ------------------------- Get functions ---------------------------*/

// Action to get projects for a given organization
export function getOrgs() {
	return dispatch => {
		dispatch({
			type: GET_ORGS_REQUEST,
		})

		fetch(`${API}/v1/organization/list_names`, {
			method: 'GET',
			headers: {
				"flourish-test-header": TEST_HEADER,
			},
			mode: 'cors',
		}).then(res => {
			if (!res.ok) {
				dispatch({
					type: GET_ORGS_FAILURE,
					error: 'Error pulling organizations.'
				})
			}

			return res.json()
		}).then(responseJSON => {
			dispatch({
				type: GET_ORGS_SUCCESS,
				organizations: responseJSON.data,
			})
		}).catch((e) => {
			dispatch({
				type: GET_ORGS_FAILURE,
				error: e || 'Error pulling organizations.'
			})
		})
	}
}

// Action to get projects for a given organization
export function getProjects(orgId) {
	return dispatch => {
		dispatch({
			type: GET_PROJECTS_REQUEST,
		})

		axios.get(`${API}/v1/project/list?organization_id=${orgId}`, {
			headers: {
				'flourish-test-header': TEST_HEADER
			}
		}).then(res => {
			const projects = res.data.data

			dispatch({
				type: GET_PROJECTS_SUCCESS,
				projects,
			})
		}).catch((e) => {
			dispatch({
				error: e || 'Error pulling projects.',
				type: GET_PROJECTS_FAILURE,
			})
		})
	}
}


/* Select functions */

// Action to select a given organization
export function selectOrg(org) {
	return {
		type: SELECT_ORG,
		org,
	}
}

// Action to select projects
export function selectProjects(projects) {
	return {
		type: SELECT_PROJECTS,
		projects,
	}
}

// Action to select projects
export function selectProj(proj) {
	return {
		type: SELECT_PROJ,
		proj,
	}
}


/* Set functions */

// Action to trigger a one-time donation
// TODO ensure this route is working
export function setDonateNow(params, stripeToken, callBack) {
	const {
		name,
		email,
		donation,
		org,
		proj,
	} = params

	return dispatch => {
		dispatch({
			type: DONATE_NOW_REQUEST,
		})

		let resStatus = true

		const fullName = name.split(' ')
		const firstName = fullName[0]
		const lastName = fullName[fullName.length - 1]

		stripeToken.then(token => {
			if (proj.id) {
				fetch(`${API}/v1/donation/process_not_authorized`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'flourish-test-header': TEST_HEADER
					},
					body: JSON.stringify({
						first_name: firstName,
						last_name: lastName,
						token: token.token.id,
						email,
						project_id: proj.id,
						organization_id: org.id,
						sum: parseFloat(donation),
					}),
				}).then(res => {
					resStatus = res.ok
					return res.json()
				}).then((responseJSON) => {
					if (resStatus) {
						dispatch({
							type: DONATE_NOW_SUCCESS,
						})
						dispatch({
							type: MESSAGE,
							message: 'Thanks for donating!'
						})

						callBack()
					} else {
						dispatch({
							error: responseJSON.validation_errors || 'Error donating.',
							type: DONATE_NOW_FAILURE,
						})
					}
				}).catch((e) => {
					dispatch({
						error: e || 'Error donating.',
						type: DONATE_NOW_FAILURE,
					})
				})
			} else {
				dispatch({
					type: DONATE_NOW_FAILURE,
				})
			}
		}).catch((e) => {
			dispatch({
				error: e || 'Error donating.',
				type: DONATE_NOW_FAILURE,
			})
		})
	}
}

// Action to set a user's organization
export function setOrg(orgId, userId, accessToken, callback = () => {}) {
	return dispatch => {
		dispatch({
			type: SET_ORG_REQUEST,
		})

		fetch(`${API}/v1/user/set_organization`, {
			method: 'POST',
			headers: {
				authorization: accessToken,
				'Content-Type': 'application/json',
				'flourish-test-header': TEST_HEADER
			},
			body: JSON.stringify({
				user_id: userId,
				organization_id: orgId,
			})
		}).then(res => {
			if (!res.ok) {
				dispatch({
					error: 'Error setting your organization.',
					type: SET_ORG_FAILURE,
				})
			}

			return res.json()
		}).then(() => {
			dispatch({
				type: SET_ORG_SUCCESS,
			})

			callback()
		}).catch((e) => {
			dispatch({
				error: e || 'Error setting your organization.',
				type: SET_ORG_FAILURE,
			})
		})
	}
}

// Action to set a user's organization
export function setProjects(userId, projIds, accessToken, callback = () => {}) {
	return dispatch => {
		dispatch({
			type: SET_PROJ_REQUEST,
		})

		fetch(`${API}/v1/user/set_projects`, {
			method: 'POST',
			headers: {
				authorization: accessToken,
				'Content-Type': 'application/json',
				'flourish-test-header': TEST_HEADER
			},
			body: JSON.stringify({
				user_id: userId,
				project_ids: projIds,
			})
		}).then((res) => {
			if (!res.ok) {
				dispatch({
					error: 'Error setting your projects.',
					type: SET_PROJ_FAILURE,
				})
			}

			return res.json()
		}).then(() => {
			dispatch({
				type: SET_PROJ_SUCCESS,
			})

			callback()
		}).catch((e) => {
			dispatch({
				error: e || 'Error setting your projects.',
				type: SET_PROJ_FAILURE,
			})
		})
	}
}

// Action to set a user's daily donation
export function setDailyDonation(sum, userId, accessToken, callback = () => {}) {
	return dispatch => {
		dispatch({
			type: SET_DAILY_DONATION_REQUEST,
		})

		fetch(`${API}/v1/scheduled_donation/create`, {
			method: 'POST',
			headers: {
				authorization: accessToken,
				'Content-Type': "application/json; charset=utf-8",
				'flourish-test-header': TEST_HEADER
			},
			body: JSON.stringify({
				user_id: userId,
				sum,
				period: 'Day'
			})
		}).then((res) => {
			if (!res.ok) {
				dispatch({
					error: 'Error setting daily donation.',
					type: SET_DAILY_DONATION_FAILURE,
				})
			}

			return res.json()
		}).then(() => {
			dispatch({
				type: SET_DAILY_DONATION_SUCCESS,
			})

			callback()
		}).catch((e) => {
			dispatch({
				error: e || 'Error setting daily donation.',
				type: SET_DAILY_DONATION_FAILURE,
			})
		})
	}
}

// First action to handle one time authorized donations
export function processOneTimeDonation(token, key, sum, projId, orgId, callBack) {
	return dispatch => {
		dispatch({
			type: ONE_TIME_DONATION_AUTHED_REQUEST,
		})

		exchangeToken(token, key, dispatch).then((bearer) => {
			oneTimeDonationAuthorized(bearer, sum, projId, orgId, dispatch, callBack)
		}).catch((err) => {
			dispatch({
				error: getError(err) || 'Error donating.',
				type: ONE_TIME_DONATION_AUTHED_FAILURE,
			})
		})
	}
}

// Second action to handle one time authorized donations
export function oneTimeDonationAuthorized(bearer, sum, projId, orgId, dispatch, callBack) {
	let resStatus = true

	return fetch(`${API}/v1/donation/process_authorized`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			authorization: bearer.data,
			'flourish-test-header': TEST_HEADER
		},
		body: JSON.stringify({
			sum,
			organization_id: parseInt(orgId, 10),
			project_id: parseInt(projId, 10),
		}),
	}).then((res) => {
		resStatus = res.ok
	}).then(() => {
		if (resStatus) {
			dispatch({
				type: ONE_TIME_DONATION_AUTHED_SUCCESS,
			})

			dispatch({
				type: MESSAGE,
				message: 'Thanks for donating!',
			})

			callBack()
		} else {
			dispatch({
				error: 'Error donating.',
				type: ONE_TIME_DONATION_AUTHED_FAILURE,
			})
		}
	}).catch((e) => {
		dispatch({
			error: getError(e) || 'Error donating.',
			type: ONE_TIME_DONATION_AUTHED_FAILURE,
		})
	})
}


// Action to link bank account to user
export function linkBankAccount(token, userId, accessToken) {
	return dispatch => {
		dispatch({
			type: LINK_BANK_REQUEST,
		})

		fetch(`${API}/v1/account/link`, {
			method: 'POST',
			headers: {
				authorization: accessToken,
				'Content-Type': 'application/json',
				'flourish-test-header': TEST_HEADER
			},
			body: JSON.stringify({
				user_id: userId,
				token,
			})
		}).then((res) => {
			if (!res.ok) {
				dispatch({
					error: 'Error linking bank account.',
					type: LINK_BANK_FAILURE,
				})
			}

			return res.json()
		}).then(() => {
			dispatch({
				type: LINK_BANK_SUCCESS,
			})
		}).catch((e) => {
			dispatch({
				error: e || 'Error linking bank account.',
				type: LINK_BANK_FAILURE,
			})
		})
	}
}

// Action to link credit card to user
// Action to link bank account to user
export function linkCreditCard(userId, token, mask, accessToken) {
	return dispatch => {
		dispatch({
			type: LINK_CREDIT_CARD_REQUEST,
		})

		fetch(`${API}/v1/account/link_cc`, {
			method: 'POST',
			headers: {
				authorization: accessToken,
				'Content-Type': 'application/json',
				'flourish-test-header': TEST_HEADER
			},
			body: JSON.stringify({
				user_id: userId,
				token,
				mask,
			})
		}).then((res) => {
			if (!res.ok) {
				dispatch({
					error: 'Error linking bank account.',
					type: LINK_CREDIT_CARD_FAILURE,
				})
			}

			return res.json()
		}).then(() => {
			dispatch({
				type: LINK_CREDIT_CARD_SUCCESS,
			})
		}).catch((e) => {
			dispatch({
				error: e || 'Error linking bank account.',
				type: LINK_CREDIT_CARD_FAILURE,
			})
		})
	}
}

/* Clear functions */

// Action to clear selected org and proj
export function clearOrgAndProj() {
	return {
		type: CLEAR_ORG_AND_PROJ,
	}
}

// Action to clear projects
export function clearProjects() {
	return {
		type: CLEAR_PROJECTS,
	}
}

// Action to clear the selected project
export function clearSelectedProj() {
	return {
		type: CLEAR_SELECTED_PROJ,
	}
}

// Action to clear entire donate now state
export function clearDonateNow() {
	return {
		type: CLEAR_DONATE_NOW,
	}
}

// Action to clear errors from redux state
export function clearErrors() {
	return {
		type: CLEAR_ERRORS,
	}
}
