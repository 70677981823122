import axios from 'axios'

// Import helper methods
import {getError} from './error'
import {getProjects} from './'

// Import action types
import {
	ADD_ORG_REQUEST,
	ADD_ORG_FAILURE,
	ADD_ORG_SUCCESS,
	EDIT_ORG_REQUEST,
	EDIT_ORG_FAILURE,
	EDIT_ORG_SUCCESS,
	ADD_PROJ_REQUEST,
	ADD_PROJ_FAILURE,
	ADD_PROJ_SUCCESS,
	ADD_ADMIN_REQUEST,
	ADD_ADMIN_FAILURE,
	ADD_ADMIN_SUCCESS,
	EDIT_PROJ_REQUEST,
	EDIT_PROJ_FAILURE,
	EDIT_PROJ_SUCCESS,
	DELETE_PROJ_REQUEST,
	DELETE_PROJ_FAILURE,
	DELETE_PROJ_SUCCESS,
	CLEAR_PROJECTS,
	CLEAR_ERRORS,
	MESSAGE,
	ERROR,
	GET_ORG_REQUEST,
	GET_ORG_SUCCESS,
	CLEAR_ORG,
	CHANGE_SELECTED_PROJECT,
	SET_ACTIVE_REQUEST,
	SET_ACTIVE_FAILURE,
	GET_DONATIONS_SUCCESS,
	GET_DONATIONS_FAILURE,
	GET_DONATIONS_REQUEST,
	API,
	TEST_HEADER,
	GET_ORG_CATEGORIES,
	GET_ORG_CATEGORIES_FAIL,
	EDIT_ORG_VALS,
	SEARCH_USERS_EMAIL_SUCCESS,
	SEARCH_USERS_EMAIL_REQUEST,
	CLEAR_USERS,
} from './types'


// Action to get all donations
export function getDonations(orgId, token) {
	return dispatch => {
		dispatch({
			type: GET_DONATIONS_REQUEST,
		})

		axios.get(`${API}/v1/donation/list/?organization_id=${orgId}`, {
			headers: {
				authorization: token,
				'flourish-test-header': TEST_HEADER,
			}})
			.then(res => {
				dispatch({
					type: GET_DONATIONS_SUCCESS,
					donations: res.data.data,
				})
			})
			.catch(err => dispatch({
				type: GET_DONATIONS_FAILURE,
				error: getError(err) || 'Error getting donations.',
			}))
	}
}

// Action to add an organization
export function addOrg(org, token, callback) {
	return dispatch => {
		dispatch({
			type: ADD_ORG_REQUEST,
		})

		const addressFormatted = {
			state_id: org.state,
			city: org.city,
			street_address: org.street_address,
			zip: org.zip,
		}

		org.address = addressFormatted

		axios.post(`${API}/v1/organization/create`, org, {
			headers: {
				authorization: token,
				'flourish-test-header': TEST_HEADER,
			}
		}).then(res => {
			dispatch({
				type: ADD_ORG_SUCCESS,
			})
			dispatch({
				type: GET_ORG_SUCCESS,
				organization: res.data.data,
			})
			dispatch({
				type: MESSAGE,
				message: 'Organization added',
			})

			callback()
		}).catch(e => {
			dispatch({
				type: ADD_ORG_FAILURE,
				error: getError(e) || 'Error adding organization',
			})

			dispatch({
				type: ERROR,
				message: getError(e) || 'Error editting organization',
			})
		})
	}
}

// Action to edit an organization
export function editOrg(organization, token, callback = () => {}) {
	return dispatch => {
		dispatch({
			type: EDIT_ORG_REQUEST,
		})

		const addressFormatted = {
			state_id: organization.state,
			city: organization.city,
			street_address: organization.street_address,
			zip: organization.zip,
		}

		organization.address = addressFormatted


		axios.post(`${API}/v1/organization/update`, organization, {
			headers: {
				authorization: token,
				'Accept': 'application/json',
				'Content-Type': "application/json; charset=utf-8",
				'flourish-test-header': TEST_HEADER,
			},
		}).then((res) => {
			dispatch({
				type: EDIT_ORG_SUCCESS,
			})

			dispatch({
				type: MESSAGE,
				message: 'Organization updated',
			})

			dispatch(getOrgById(organization.id, token, ()=>callback()))
		}).catch(e => {
			dispatch({
				type: EDIT_ORG_FAILURE,
				error: getError(e) || 'Error editting organization',
			})
			dispatch({
				type: ERROR,
				message: getError(e) || 'Error editting organization',
			})
		})
	}
}

// Action to change selected project
export function changeSelectedProject(project) {
	return {
		type: CHANGE_SELECTED_PROJECT,
		project,
	}
}

/*
Actions for organization management
*/

// Action to edit an organization
export function addAdmin(orgId, user_ids, accessToken, callBack) {
	return dispatch => {
		dispatch({
			type: ADD_ADMIN_REQUEST,
		})

		axios.post(`${API}/v1/organization/set_admins`, {
			id: orgId,
			user_ids
		}, {
			headers: {
				authorization: accessToken,
				'flourish-test-header': TEST_HEADER,
			},
			method: 'GET',
		}).then(() => {
			dispatch({
				type: ADD_ADMIN_SUCCESS,
			})
			dispatch({
				type: MESSAGE,
				message: 'Admin added',
			})
			dispatch(getOrgById(orgId, accessToken, callBack))
		})
			.catch(e => {
				dispatch({
					type: ADD_ADMIN_FAILURE,
					error: getError(e) || 'Invalid organization Id or user Id.',
				})
			})
	}
}


// Actions for project management

// Action to add a project
export function addProj(data, token, callback = () => {}) {
	return dispatch => {
		dispatch({
			type: ADD_PROJ_REQUEST,
		})

		axios.post(`${API}/v1/project/create`, {
			...data
		}, {
			headers: {
				authorization: token,
				'flourish-test-header': TEST_HEADER,
			}
		}).then(() => {
			window.location.reload()

			dispatch({
				type: ADD_PROJ_SUCCESS,
			})

			dispatch(getProjects(data.organization_id))

			dispatch({
				type: MESSAGE,
				message: 'Project added',
			})

			callback()
		}).catch(e => {
			dispatch({
				type: ADD_PROJ_FAILURE,
				error: getError(e) || 'Error adding project',
			})
		})
	}
}

// Action to edit a project
export function editProj(data, token) {
	return dispatch => {
		dispatch({
			type: EDIT_PROJ_REQUEST,
		})
		axios.post(`${API}/v1/project/update`, {...data}, {
			headers: {
				authorization: token,
				'flourish-test-header': TEST_HEADER,
			},
		})
			.then(() => {
				dispatch({
					type: EDIT_PROJ_SUCCESS,
				})
				dispatch({
					type: MESSAGE,
					message: 'Project updated',
				})
			})
			.catch(e => {
				dispatch({
					type: EDIT_PROJ_FAILURE,
					error: getError(e) || 'Error editting project',
				})
			})
	}
}

// Action to delete a project
export function deleteProject(id, accessToken) {
	return dispatch => {
		dispatch({
			type: DELETE_PROJ_REQUEST,
		})
		axios.post(`${API}/v1/project/set_is_active`, {
			id,
			is_active: 0
		}, {
			headers: {
				authorization: accessToken,
				'flourish-test-header': TEST_HEADER,
			}
		})
			.then(() => {
				window.location = window.location.origin + '/admin/projects'
				dispatch({
					type: DELETE_PROJ_SUCCESS,
				})
				dispatch({
					type: MESSAGE,
					message: 'Project deleted',
				})
			})
			.catch(e => {
				dispatch({
					type: DELETE_PROJ_FAILURE,
					error: getError(e) || 'Error deleting project',
				})
			})
	}
}

// Other admin actions that don't interact with backend

// Action to clear organization
export function clearOrg() {
	return {
		type: CLEAR_ORG,
	}
}

// Action to clear errors
export function clearErrors() {
	return {
		type: CLEAR_ERRORS,
	}
}

// Action to clear projects
export function clearProjects() {
	return {
		type: CLEAR_PROJECTS,
	}
}

export function editOrgVals(e) {
	return dispatch => {
		dispatch({
			type: EDIT_ORG_VALS,
			payload: e,
		})
	}
}

export function getOrgCategories(callback) {
	return dispatch => {
		axios.get(`${API}/v1/organization_category/list`)
			.then(res => {
				dispatch({
					type: GET_ORG_CATEGORIES,
					payload: res.data.data,
				})

				callback()
			}).catch((err) => {
				dispatch({
					type: GET_ORG_CATEGORIES_FAIL,
					error: err,
				})
			})
	}
}

// Set orgState
export const setOrgState = (
	state_to_change,
	new_state,
	orgId,
	token
) => dispatch => {
	dispatch({
		type: GET_ORG_REQUEST,
	})

	switch (state_to_change) {
	case 'is_active':
		dispatch(setActive(new_state, orgId, token))
		break
	case 'is_featured':
		dispatch(setFeatured(new_state, orgId, token))
		break
	case 'is_primary_featured':
		dispatch(setPrimaryFeatured(new_state, orgId, token))
		break
	case 'is_signed':
		dispatch(setSigned(new_state, orgId, token))
		break
	default:
		null
	}
}

// Action to set an organization to active
const setActive = (is_active, orgId, token) => dispatch => {
	dispatch({
		type: SET_ACTIVE_REQUEST,
	})

	axios.post(`${API}/v1/organization/set_is_active`, {
		id: orgId,
		is_active,
	}, {
		headers: {
			authorization: token,
			'flourish-test-header': TEST_HEADER,
		}
	})
		.then(res => {
			dispatch({
				type: GET_ORG_SUCCESS,
				organization: res.data.data,
			})
			dispatch({
				type: MESSAGE,
				message: 'Organization Set Active',
			})
		})
		.catch(e => {
			dispatch({
				type: SET_ACTIVE_FAILURE,
				error: getError(e) || 'Error setting active status',
			})
			dispatch({
				type: ERROR,
				message: 'Set Active Error',
			})
		})
}

const setFeatured = (is_featured, orgId, token) => dispatch => {
	axios.post(`${API}/v1/organization/set_is_featured`, {
		id: orgId,
		is_featured,
	}, {
		headers: {
			authorization: token,
			'flourish-test-header': TEST_HEADER,
		}
	})
		.then(res => {
			dispatch({
				type: GET_ORG_SUCCESS,
				organization: res.data.data,
			})
			dispatch({
				type: MESSAGE,
				message: 'Organization Set Active',
			})
		})
		.catch(e => {
			dispatch({
				type: SET_ACTIVE_FAILURE,
				error: getError(e) || 'Error setting active status',
			})
			dispatch({
				type: ERROR,
				message: 'Set Featured Error',
			})
		})
}

const setPrimaryFeatured = (is_primary_featured, orgId, token) => dispatch => {
	axios.post(`${API}/v1/organization/set_is_primary_featured`, {
		id: orgId,
		is_primary_featured,
	}, {
		headers: {
			authorization: token,
			'flourish-test-header': TEST_HEADER,
		}
	})
		.then(res => {
			dispatch({
				type: GET_ORG_SUCCESS,
				organization: res.data.data,
			})
			dispatch({
				type: MESSAGE,
				message: 'Organization Set Primary',
			})
		})
		.catch(e => {
			dispatch({
				type: SET_ACTIVE_FAILURE,
				error: getError(e) || 'Error setting active status',
			})
			dispatch({
				type: ERROR,
				message: 'Set Primary Error',
			})
		})
}

const setSigned = (is_signed, orgId, token) => dispatch => {
	axios.post(`${API}/v1/organization/set_is_signed`, {
		id: orgId,
		is_signed,
	}, {
		headers: {
			authorization: token,
			'flourish-test-header': TEST_HEADER,
		}
	})
		.then(res => {
			dispatch({
				type: GET_ORG_SUCCESS,
				organization: res.data.data,
			})
			dispatch({
				type: MESSAGE,
				message: 'Organization Signed',
			})
		}).catch(() => {
			dispatch({
				type: ERROR,
				message: 'Set Signed Error',
			})
		})
}

export const searchUsersByEmail = (email, token, callback) => dispatch => {
	dispatch({
		type: SEARCH_USERS_EMAIL_REQUEST,
	})

	axios.get(`${API}/v1/user/search_for_email?email_part=${email}`, {
		headers: {
			authorization: token,
			'flourish-test-header': TEST_HEADER,
		},
	}).then(res => {
		dispatch({
			type: SEARCH_USERS_EMAIL_SUCCESS,
			payload: res.data.data,
		})
		callback()
	})
}

export const clearUsers = () => dispatch =>
	dispatch({
		type: CLEAR_USERS,
	})

export const getOrgById = (id, token, callback) => dispatch => {
	dispatch({
		type: GET_ORG_REQUEST,
		pending: true,
	})

	axios.get(`${API}/v1/organization/get?id=${id}`, {
		headers: {
			authorization: token,
			'flourish-test-header': TEST_HEADER,
		},
	}).then(resp => {
		dispatch({
			type: GET_ORG_SUCCESS,
			organization: resp.data.data,
		})

		callback()
	}).catch((e) => {
		dispatch({
			type: ERROR,
			message: e || 'Error getting organization.',
			pending: false,
		})
	})
}

export const changeSelectedOrg = (organization) => (dispatch) => [
	dispatch({
		type: GET_ORG_SUCCESS,
		organization,
	})
]
