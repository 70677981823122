import { navigate } from '@reach/router'
import axios from 'axios'
import {updateToken} from '../util/auth'
import {getError} from './error'
import {trackEvent} from '../util'

import {
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	RESET_ACCESS_TOKEN,
	RESET_ACCESS_TOKEN_SUCCESS,
	RESET_ACCESS_TOKEN_FAIL,
	REGISTER_REQUEST,
	REGISTER_FAILURE,
	REGISTER_SUCCESS,
	GET_ORG_SUCCESS,
	LOGOUT,
	MESSAGE,
	CLEAR,
	CLEAR_AUTH,
	CHECK_ADMIN_SUCCESS,
	FORGOT_REQUEST,
	FORGOT_SUCCESS,
	FORGOT_FAILURE,
	RESET_REQUEST,
	RESET_SUCCESS,
	RESET_FAILURE,
	API,
	TEST_HEADER,
} from './types'

/*
Authentication actions that interact with backend
*/

// Action to log user in
export function login(email, password, setSession, cleanSession) {
	cleanSession()
	logout()
	clearAuth()

	return dispatch => {
		dispatch({
			type: LOGIN_REQUEST,
		})


 		axios.post(`${API}/v1/mobile/updated/firebase/auth/sign_in`, {email, password})
			.then(res => {
				const {
					first_name,
					last_name,
					adminfororganization_id,
					user_id
				} = res.data

				if (!adminfororganization_id) {
					dispatch({
						type: LOGIN_FAILURE,
						error: 'User is not an organization administrator. Please email support@flourishchange.com if you believe this message is in error'
					})
					return
				}

				const access_token = `Bearer ${res.data.access_token}`
				const userName = `${first_name} ${last_name}`
				setSession({...res.data, access_token, role: 1, userName, email, user_id})
				trackEvent('login', {email, user_id})

				axios.get(`${API}/v1/organization/get?id=${adminfororganization_id}`, {
					headers: {
						authorization: access_token,
						'flourish-test-header': TEST_HEADER,
					}
				}).then(resp => {
					dispatch({
						type: CHECK_ADMIN_SUCCESS,
					})
					dispatch({
						type: LOGIN_SUCCESS,
						accessToken: access_token,
						userName,
						role: 1,
						user: res.data
					})
					dispatch({
						type: GET_ORG_SUCCESS,
						organization: resp.data.data,
					})
					dispatch({
						type: MESSAGE,
						message: 'Successful login',
					})

					navigate('/admin')
				}).catch(() => {
					dispatch({
						type: LOGIN_FAILURE,
						error: 'Error requesting organizations.',
					})
				})
			})
			.catch(error => {
				try{
					dispatch({
						type: LOGIN_FAILURE,
						error: error.response.data.error_message
					})
				} catch(err) {
					dispatch({
						type: LOGIN_FAILURE,
						error: 'Error loggin in. Please try again or email support@flourishchange.com',
					})
				}
			})
	}
}

// Action to refresh access Token

export const refreshToken = () => (dispatch) => {
	dispatch({
		type: RESET_ACCESS_TOKEN
	})

	if (JSON.parse(localStorage.getItem('expires_at')) > new Date().getTime()) {
		return new Promise((resolve) => resolve(localStorage.getItem('access_token')))
	}

	return axios.post(`https://securetoken.googleapis.com/v1/token?key=${process.env.firebaseKey}`,
		{
			grant_type: "refresh_token",
			refresh_token: localStorage.getItem('refresh_token')
		})
		.then((res) => {
			updateToken(`Bearer ${res.data.id_token}`)
			dispatch({
				type: RESET_ACCESS_TOKEN_SUCCESS,
				payload: `Bearer ${res.data.id_token}`
			})
			return `Bearer ${res.data.id_token}`
		})
		.catch((err) => {
			console.log(err)
			dispatch({
				type: RESET_ACCESS_TOKEN_FAIL,
				payload: err
			})
		})
}

// Action to handle user sign up process
export function register(email, password, f_name, l_name, phone, ip_registered, callback = () => {}) {
	return dispatch => {
		dispatch({
			type: REGISTER_REQUEST,
		})

		axios.post(`${API}/v1/mobile/updated/firebase/auth/sign_up`, {
			email,
			password,
			first_name: f_name,
			last_name: l_name,
			phone
		}, {
			headers: {
				'Content-Type': "application/json; charset=utf-8",
				'flourish-test-header': TEST_HEADER,
			}
		}).then((res) => {
			axios.post(`${API}/v1/mobile/updated/firebase/auth/sign_in`, {email, password}, {
				headers: {
					'Content-Type': "application/json; charset=utf-8",
					'flourish-test-header': TEST_HEADER,
				}
			}).then(resp => {
				const access_token = `Bearer ${res.data.access_token}`
				dispatch({
					type: REGISTER_SUCCESS,
					user: resp.data,
					accessToken: access_token,
				})

				callback()
			}).catch(err => {
				dispatch({
					type: REGISTER_FAILURE,
					error: getError(err) || 'Error registering user',
				})
			})
		}).catch(e => {
			dispatch({
				type: REGISTER_FAILURE,
				error: getError(e) || 'Error registering user',
			})
		})
	}
}

// Action for when user forgets password, sends them a reset email
export function forgot(email) {
	return dispatch => {
		dispatch({
			type: FORGOT_REQUEST,
		})

		fetch(`${API}/v1/mobile/forgot_password`, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': "application/json; charset=utf-8",
				'flourish-test-header': TEST_HEADER,
			},
			method: 'POST',
			body: JSON.stringify({
				email,
				web_form_url: `/reset/?email=${email}`,
			}),
		}).then((res) => {
			if (!res.ok) {
				dispatch({
					type: FORGOT_FAILURE,
					error: "Enter a valid email address.",
				})
			}

			return res.json()
		}).then(() => {
			dispatch({
				type: FORGOT_SUCCESS,
			})

			dispatch({
				type: MESSAGE,
				message: 'Email sent',
			})
		}).catch(e => {
			dispatch({
				type: FORGOT_FAILURE,
				error: getError(e) || 'Enter a valid email address.',
			})
		})
	}
}

// Action for when user forgets password, allows them to reset password
export function reset(email, token, password) {
	return dispatch => {
		dispatch({
			type: RESET_REQUEST,
		})

		axios.post(`${API}/v1/user/reset_password`, {
			email,
			token,
			password,
		}, {
			headers: {
				'Accept': 'application/json',
				'Content-Type': "application/json; charset=utf-8",
				'flourish-test-header': TEST_HEADER,
			},
		}).then(() => {
			dispatch({
				type: RESET_SUCCESS,
			})
			dispatch({
				type: MESSAGE,
				message: 'Password reset',
			})
		}).catch(e => {
			dispatch({
				type: RESET_FAILURE,
				error: getError(e) || 'Uh Oh! There was an issue resetting your password. Please email support@flourishchange.com to resolve.',
			})
		})
	}
}

// Action to log a user out
export const logout = () => {
	trackEvent('logout')

	localStorage.clear()
	return {
		type: LOGOUT,
	}
}

// Action to clear auth redux state
export const clearAuth = () => {
	return {
		type: CLEAR_AUTH,
	}
}

// Action to clear redux state
export const clear = () => {
	return {
		type: CLEAR,
	}
}
