module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/bigLogo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"NxDLz1KAf0VzeP9Uk2qTb6wZBYztS1xN","devKey":"dQGTxVsTwfAeWPwrY7A50TrGYDpSzSmP","trackPage":false,"delayLoad":false,"delayLoadTime":1000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
