export default {
	title: 'Donation Setup',
	success: {
		title: 'Setup Complete',
		body: 'Now Watch Your Cause Flourish',
		icon:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/Onboarding4.png',
		button: 'Confirm and return to App',
		buttonLink: 'flourish://',
	},
	both: {
		icon:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/RoundUpDaily.png',
		title: 'Roundups + Daily Donation',
		body:
			'Round up your transactions to the nearest dollar and give a set amount each day',
	},
	roundUp: {
		icon:
			"https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/RoundUp.png",
		title: 'Roundups',
		body:
			"We'll round up every transaction to the nearest dollar, allowing you to give on auto-pilot",
	},
	daily: {
		icon:
			'https://flourishfiles.blob.core.windows.net/files/Marketing/Illustrations/PNGs/Daily.png',
		title: 'Set Your Daily Donation',
		body: 'A small donation each day can make a huge impact',
	},
	sliderSubtitle: 'Your Daily Donation Amount',
	confirmSubtitle: 'Confirm My Roundups',
}
