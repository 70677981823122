import {
	COMPLETE_TASK_REQUEST,
	COMPLETE_TASK_SUCCESS,
	COMPLETE_TASK_FAIL,
	GET_TASKS_SUCCESS
} from '../factory'

const initialState = {
	taskList: [],
	currentCompleteCount: 0,
	totalTasks: 0,
	pending: false
}
/**
 * Reducer which handles all events related to flashing error and notification messages
 */
const onboardingReducer = (state = initialState, action) => {
	switch (action.type) {
	// Flash an error
	case GET_TASKS_SUCCESS: {
		return {...state, taskList: action.payload, totalTasks: action.payload.length}
	}
	case COMPLETE_TASK_SUCCESS: {
		let currComplete = state.currentCompleteCount
		if(action.payload) {
			currComplete = currComplete + 1
		} else {
			currComplete = currComplete - 1
		}

		if (currComplete > state.totalTasks) {
			currComplete = state.totalTasks
		}
		return {...state, currentCompleteCount: currComplete}
	}
	default:
		return state
	}
}

export default onboardingReducer
