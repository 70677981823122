import moment from "moment"

import {
	FETCH_STATISTICS,
	FETCH_STATISTICS_COMPLETE,
	FETCH_AVG_SUM_SUCCESS,
	FETCH_STATISTICS_FAIL,
	FETCH_SUM_SUCCESS,
	FETCH_SUM_FAIL,
	FETCH_QUANTITY_SUCCESS,
	FETCH_DONATION_PER_DAY_SUCCESS,
	FETCH_DONATION_PER_DAY_FAIL,
	FETCH_QUANTITY_AVG_SUCCESS,
	FETCH_DONOR_DONATIONS,
	FETCH_USERS_SUCCESS,
	FETCH_USERS_FAIL,
	CLEAR_PROJECT_STATS,
	SET_DATE_RANGE
} from '../factory/types'

const initialState = {
	error: '',
	avgSum: null,
	qunatity: null,
	sum: null,
	sumError: false,
	avgQuantity: null,
	sumPerDay: [],
	sumPerDayError: false,
	sumPerDonor: [],
	pending: false,
	donorDonations: [],
	endDate: moment(),
	startDate: moment().subtract(15, 'days'),
	users: []
}

/**
 * Reducer which handles all events related to flashing error and notification messages
 */
const messageReducer = (state = { ...initialState }, action) => {
	switch (action.type) {
	case SET_DATE_RANGE:
		return {
			...state,
			startDate: action.payload.start_date,
			endDate: action.payload.end_date,
			pending: true
		}
	case FETCH_STATISTICS:
		return {
			...state,
			pending: true
		}
	case FETCH_STATISTICS_COMPLETE:
		return {
			...state,
			pending: false
		}
	case FETCH_AVG_SUM_SUCCESS:
		return {
			...state,
			avgSum: action.payload,
		}
	case FETCH_SUM_SUCCESS:
		return {
			...state,
			sum: action.payload,
			pending: false,
		}
	case FETCH_SUM_FAIL:
		return {
			...state,
			sum: 0,
			pending: false,
			sumError: true
		}
	case FETCH_QUANTITY_SUCCESS:
		return {
			...state,
			quantity: action.payload,
		}
	case FETCH_DONATION_PER_DAY_SUCCESS:
		return {
			...state,
			sumPerDay: action.payload,
		}
	case FETCH_DONATION_PER_DAY_FAIL:
		return {
			...state,
			pending: false,
			sumPerDay: -1,
			sumPerDayError: true
		}
	case FETCH_QUANTITY_AVG_SUCCESS:
		return {
			...state,
			avgQuantity: action.payload,
		}
	case FETCH_DONOR_DONATIONS:
		return {
			...state,
			donorDonations: action.payload,
		}
	case FETCH_USERS_SUCCESS:
		return {
			...state,
			users: action.payload,
			pending: false
		}
	case FETCH_USERS_FAIL:
	case FETCH_STATISTICS_FAIL:
		return {
			...state,
			pending: false,
			error: action.payload,
		}
	case CLEAR_PROJECT_STATS:
		return {
			...state,
			avgSum: null,
			quantity: null,
			sumPerDay: []
		}
	default:
		return {
			...state
		}
	}
}

export default messageReducer
