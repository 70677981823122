import states from '../states'

export default {
	title: 'Create a New Organization',
	bgRightColum:
		'https://flourishfiles.blob.core.windows.net/files/Marketing/Patterns/LogoPatternWeb.jpg',
	createForm: {
		fields: [
			[
				{
					label: 'Name',
					name: 'title',
					field: 'default',
					type: 'text',
					maxWidth: '50%',
				},
				{
					label: 'Category',
					name: 'category_id',
					field: 'select',
					options: [
						{
							text: 'Family Services',
							value: '1',
						},
						{
							text: 'Family Services2',
							value: '2',
						},
						{
							text: 'Family Services3',
							value: '2',
						},
						{
							text: 'Family Services4',
							value: '2',
						},
					],
					maxWidth: '50%',
				},
			],
			[
				{
					label: 'Phone',
					name: 'phone',
					placeholder: '555-214-5544',
					field: 'default',
					type: 'text',
					maxWidth: '50%',
				},
				{
					label: 'Contact Email',
					name: 'email',
					placeholder: 'nonprofits@flourishchange.com',
					field: 'default',
					type: 'email',
					maxWidth: '50%',
				},
			],
			[
				{
					label: 'Website',
					name: 'website',
					placeholder: 'http://flourishchange.com',
					field: 'default',
					type: 'text',
					maxWidth: '50%',
				},
				{
					label: 'Image URL',
					name: 'image_url',
					placeholder: 'http://flourishchange.com/my_best_img.jpg',
					field: 'file',
					type: 'file',
					maxWidth: '50%',
				},
			],
			[
				{
					label: 'Address',
					name: 'street_address',
					placeholder: 'Street Address',
					field: 'default',
					type: 'text',
					maxWidth: '100%',
				},
			],
			[
				{
					label: 'City',
					name: 'city',
					placeholder: 'City',
					field: 'default',
					type: 'text',
					maxWidth: '33.3333333333%',
				},
				{
					label: 'State',
					name: 'state',
					field: 'select',
					options: states,
					maxWidth: '33.3333333333%',
				},
				{
					label: 'Postal Code',
					name: 'zip',
					placeholder: 'ZIP Code',
					field: 'default',
					maxWidth: '33.3333333333%',
				},
			],
			[
				{
					label: 'Mission',
					name: 'mission',
					placeholder: "What's your mission?",
					field: 'textarea',
					columns: '40',
					rows: '4',
					type: 'text',
					maxWidth: '100%',
					style: {
						background: 'inherit',
						minHeight: '5em'
					},
				},
			],
			[
				{
					label: 'EIN',
					name: 'ein',
					placeholder: '555214554',
					field: 'default',
					maxWidth: '33.3333333333%',
				},
				{
					label: 'Account Number',
					name: 'bank_account',
					placeholder: '5552145',
					field: 'default',
					type: 'number',
					maxWidth: '33.3333333333%',
				},
				{
					label: 'Routing Number',
					name: 'routing_number',
					placeholder: '5552145',
					field: 'default',
					type: 'number',
					maxWidth: '33.3333333333%',
				},
			],
			[
				{
					label: 'Deductibility Code',
					name: 'deductibility_code',
					placeholder: '6',
					field: 'default',
					type: 'number',
					maxWidth: '33.3333333333%',
				},
				{
					label: 'Reported Revenue',
					name: 'recent_reported_revenue',
					placeholder: '0',
					field: 'default',
					type: 'number',
					maxWidth: '33.3333333333%',
				},
				{
					label: 'Classification Code',
					name: 'classification_code',
					placeholder: '12',
					field: 'default',
					type: 'number',
					maxWidth: '33.3333333333%',
				},
			],
		],
		submitField: {
			text: 'Save',
		},
	},
}
