import axios from 'axios'

// Import helper methods
import {getError} from './error'

import {
	FETCH_STATISTICS,
	FETCH_STATISTICS_COMPLETE,
	FETCH_AVG_SUM_SUCCESS,
	FETCH_SUM_SUCCESS,
	FETCH_SUM_FAIL,
	FETCH_DONATION_PER_DAY_FAIL,
	FETCH_QUANTITY_SUCCESS,
	FETCH_DONATION_PER_DAY_SUCCESS,
	FETCH_QUANTITY_AVG_SUCCESS,
	FETCH_DONOR_DONATIONS,
	FETCH_USERS_SUCCESS,
	FETCH_USERS_FAIL,
	CLEAR_PROJECT_STATS,
	SET_DATE_RANGE,
	ERROR,
	API,
	TEST_HEADER,
} from './types'

export const getStatistics = (params, token) => (dispatch) => {
	const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&')

	dispatch({
		type: FETCH_STATISTICS
	})

	dispatch(getAvgDonationSize(queryString, token))
	dispatch(getTotalDoantions(queryString, token))
	dispatch(getQuantity(queryString, token))
	dispatch(donationPerDay(queryString, token))
	dispatch(getQuantityAvg(queryString, token))
	dispatch(getDonorSum(queryString, token))
	dispatch(getUsers(token))
	dispatch({
		type: FETCH_STATISTICS_COMPLETE
	})
}


export const getAvgDonationSize = (queryString, token) => (dispatch) => {
	fetch(`${API}/v1/donation/sum_average?${encodeURI(queryString)}`, {
		headers: {
			method: 'GET',
			authorization: token,
			'flourish-test-header': TEST_HEADER
		}
	}).then((res) => {
		if(!res.ok) {
			dispatch({
				type: ERROR,
				payload: res
			})
		}

		return res.json()
	}).then((res) => {
		dispatch({
			type: FETCH_AVG_SUM_SUCCESS,
			payload: res.data
		})
	}).catch((e) => {
		dispatch({
			type: ERROR,
			payload: e
		})
	})
}

export const getTotalDoantions = (queryString, token) => (dispatch) => {
	fetch(`${API}/v1/donation/sum?${encodeURI(queryString)}`, {
		headers: {
			method: 'GET',
			authorization: token,
			'flourish-test-header': TEST_HEADER
		}
	}).then((res) => {
		if(!res.ok) {
			dispatch({
				type: ERROR,
				payload: res
			})
		}

		return res.json()
	}).then((res) => {
		dispatch({
			type: FETCH_SUM_SUCCESS,
			payload: res.data
		})
	}).catch((e) => {
		dispatch({
			type: FETCH_SUM_FAIL,
			payload: e
		})
	})
}

export const getQuantity = (queryString, token) => (dispatch) => {
	fetch(`${API}/v1/donation/qty?${encodeURI(queryString)}`, {
		headers: {
			method: 'GET',
			authorization: token,
			'flourish-test-header': TEST_HEADER
		}
	}).then((res) => {
		if(!res.ok) {
			dispatch({
				type: ERROR,
				payload: res
			})
		}

		return res.json()
	}).then((res) => {
		dispatch({
			type: FETCH_QUANTITY_SUCCESS,
			payload: res.data
		})
	}).catch((e) => {
		dispatch({
			type: ERROR,
			payload: e
		})
	})
}

export const donationPerDay = (queryString, token) => (dispatch) => {
	axios.get(`${API}/v1/donation/sum_per_day?${encodeURI(queryString)}`, {
		headers: {
			method: 'GET',
			authorization: token,
			'flourish-test-header': TEST_HEADER
		}
	}).then((res) => {
		dispatch({
			type: FETCH_DONATION_PER_DAY_SUCCESS,
			payload: res.data.data
		})
	}).catch((e) => {
		dispatch({
			type: FETCH_DONATION_PER_DAY_FAIL,
			payload: e
		})
		dispatch({
			type: FETCH_STATISTICS_COMPLETE,
			pending: false,
		})
	})
}

export const getQuantityAvg = (queryString, token) => (dispatch) => {
	fetch(`${API}/v1/donation/qty_average?${encodeURI(queryString)}`, {
		headers: {
			method: 'GET',
			authorization: token,
			'flourish-test-header': TEST_HEADER
		}
	}).then((res) => {
		if(!res.ok) {
			dispatch({
				type: ERROR,
				payload: res
			})
		}

		return res.json()
	}).then((res) => {
		dispatch({
			type: FETCH_QUANTITY_AVG_SUCCESS,
			payload: res.data
		})
	}).catch((e) => {
		dispatch({
			type: ERROR,
			payload: e
		})
	})
}

export const getDonorSum = (queryString, token) => (dispatch) => {
	dispatch({
		type: FETCH_STATISTICS
	})

	const params = new URLSearchParams(queryString)
	const organization_id = params.get('organization_id')

	axios.get(`${API}/v1/donation/sum_per_donor?organization_id=${organization_id}`, {
		headers: {
			method: 'GET',
			authorization: token,
			'flourish-test-header': TEST_HEADER
		}
	}).then((res) => {
		dispatch({
			type: FETCH_DONOR_DONATIONS,
			payload: res.data.data,
		})

		dispatch({
			type: FETCH_STATISTICS_COMPLETE,
			pending: false,
		})
	}).catch((e) => {
		dispatch({
			type: ERROR,
			payload: e,
		})

		dispatch({
			type: FETCH_STATISTICS_COMPLETE,
			pending: false,
		})
	})
}

export const setDateRange = ({start_date, end_date}) => (dispatch) => {
	dispatch({
		type: SET_DATE_RANGE,
		payload: {start_date, end_date}
	})
}

export const clearProjectStats = () => (dispatch) => {
	dispatch({
		type: CLEAR_PROJECT_STATS
	})
}

export const getUsers = (token) => (dispatch) => {
	dispatch({
		type: FETCH_STATISTICS
	})

	axios.get(`${API}/v1/mobile/updated/organization/get_donors`, {
		headers: {
			method: 'GET',
			authorization: token,
			'flourish-test-header': TEST_HEADER
		}
	}).then((res) => {
		dispatch({
			type: FETCH_USERS_SUCCESS,
			payload: res.data,
		})
	}).catch((e) => {
		dispatch({
			type: FETCH_USERS_FAIL,
			payload: getError(e) || "Error Fetching Users",
		})
	})
}
