/**
 * Reducer which handles all events related to user actions
 */

// Import action types
import {
	EXCHANGE_TOKEN_SUCCESS,
	EXCHANGE_TOKEN_REQUEST,
	EXCHANGE_TOKEN_FAIL,
	GET_ACCOUNTS_SUCCESS,
	GET_ACCOUNTS_FAIL,
	GET_ACCOUNTS_REQUEST,
	USER_LOADING,
	USER_ERROR,
	USER_SUCCESS
} from '../factory/types'

const initialState = {
	token: '',
	accountList: [],
	error: '',
	success: {},
	pending: false,
}

const userReducer = (state = initialState, action) => {
	switch (action.type) {
	/*
		Cases for user management
		*/
	case EXCHANGE_TOKEN_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.token = action.payload
		newState.pending = false
		return newState
	}
	case EXCHANGE_TOKEN_REQUEST:
	case GET_ACCOUNTS_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case EXCHANGE_TOKEN_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = 'cannot exchange tokens'
		return newState
	}
	case GET_ACCOUNTS_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.accountList = action.payload
		newState.accountsRecieved = true
		newState.pending = false
		return newState
	}
	case GET_ACCOUNTS_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.accountsRecieved = true
		newState.error = 'unable to get accounts'
		return newState
	}
	case USER_LOADING: {
		const newState = Object.assign({}, state)
		newState.loading = true
		newState.error = ''
		return newState
	}
	case USER_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.success[action.payload] = true
		newState.loading = false
		return newState
	}
	case USER_ERROR: {
		const newState = Object.assign({}, state)
		newState.error = action.payload
		newState.loading = false
		return newState
	}
	default:
		return state
	}
}

export default userReducer
