/**
 * Reducer which handles all events related to user actions
 */

// Import action types
import {
	START_SUBSCRIPTION_REQUEST,
	START_SUBSCRIPTION_SUCCESS,
	START_SUBSCRIPTION_FAIL
} from '../factory/types'

const initialState = {
	pending: false,
	error: ''
}

const subscriptionReducer = (state = initialState, action) => {
	switch (action.type) {
	/*
		Cases for user management
		*/

	case START_SUBSCRIPTION_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case START_SUBSCRIPTION_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		return newState
	}
	case START_SUBSCRIPTION_FAIL: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = "Couldn't start subscription. Please call Zach at 817.823.9966"
		return newState
	}
	default:
		return state
	}
}

export default subscriptionReducer
