export default {
	title: 'Listing all organizations',
	table: {
		thead: [
			{
				title: 'ID',
				width: '5%',
			},
			{
				title: 'Name',
				width: '50%',
			},
			{
				title: 'Projects',
				width: '15%',
			},
			{
				title: 'Donations',
				width: '15%',
			},
			{
				title: 'Donors',
				width: '15%',
			},
		],
		tbody: [
			[1, 'Wounded Warrior Project', 'Projects', 'Donations', 'Donors'],
			[2, 'The RELIEF Foundation', 'Projects', 'Donations', 'Donors'],
			[
				3,
				'Cooperative Baptist Fellowship',
				'Projects',
				'Donations',
				'Donors',
			],
			[4, 'Trevor Project	', 'Projects', 'Donations', 'Donors'],
			[5, 'NORML', 'Projects', 'Donations', 'Donors'],
			[6, 'Nature Conservancy', 'Projects', 'Donations', 'Donors'],
		],
		urlBase: '/organizations',
	},
}
