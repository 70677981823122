// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-admin-project-js": () => import("../src/pages/admin/project.js" /* webpackChunkName: "component---src-pages-admin-project-js" */),
  "component---src-pages-mobile-donation-flow-js": () => import("../src/pages/mobileDonationFlow.js" /* webpackChunkName: "component---src-pages-mobile-donation-flow-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-checklist-js": () => import("../src/pages/admin/checklist.js" /* webpackChunkName: "component---src-pages-admin-checklist-js" */),
  "component---src-pages-admin-donations-index-js": () => import("../src/pages/admin/donations/index.js" /* webpackChunkName: "component---src-pages-admin-donations-index-js" */),
  "component---src-pages-admin-donations-receipts-js": () => import("../src/pages/admin/donations/receipts.js" /* webpackChunkName: "component---src-pages-admin-donations-receipts-js" */),
  "component---src-pages-admin-donors-index-js": () => import("../src/pages/admin/donors/index.js" /* webpackChunkName: "component---src-pages-admin-donors-index-js" */),
  "component---src-pages-admin-donors-users-js": () => import("../src/pages/admin/donors/users.js" /* webpackChunkName: "component---src-pages-admin-donors-users-js" */),
  "component---src-pages-admin-index-js": () => import("../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-admin-one-click-relationships-custom-messages-js": () => import("../src/pages/admin/OneClickRelationships/customMessages.js" /* webpackChunkName: "component---src-pages-admin-one-click-relationships-custom-messages-js" */),
  "component---src-pages-admin-one-click-relationships-index-js": () => import("../src/pages/admin/OneClickRelationships/index.js" /* webpackChunkName: "component---src-pages-admin-one-click-relationships-index-js" */),
  "component---src-pages-admin-organization-js": () => import("../src/pages/admin/organization.js" /* webpackChunkName: "component---src-pages-admin-organization-js" */),
  "component---src-pages-admin-projects-js": () => import("../src/pages/admin/projects.js" /* webpackChunkName: "component---src-pages-admin-projects-js" */),
  "component---src-pages-forgot-js": () => import("../src/pages/forgot.js" /* webpackChunkName: "component---src-pages-forgot-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-js": () => import("../src/pages/reset.js" /* webpackChunkName: "component---src-pages-reset-js" */),
  "component---src-pages-unsubscribe-js": () => import("../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

