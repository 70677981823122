export default {
	title: 'Add an admin',
	organizations: {
		label: 'Organizations',
		field: 'select',
		options: [
			{
				value: 1,
				text: 'Wounded Warrior Project',
			},
			{
				value: 2,
				text: 'The RELIEF Foundation',
			},
			{
				value: 3,
				text: 'Cooperative Baptist Fellowship',
			},
			{
				value: 4,
				text: 'Trevor Project',
			},
			{
				value: 5,
				text: 'NORML',
			},
			{
				value: 6,
				text: 'Nature Conservancy',
			},
			{
				value: 7,
				text: 'Young Life',
			},
			{
				value: 8,
				text: 'American Heart Association',
			},
			{
				value: 9,
				text: 'Salvation Army',
			},
			{
				value: 10,
				text: 'Boys and Girls Clubs of America',
			},
		],
	},
	searchUser: {
		label: 'Search Email Addresses',
		field: 'default',
		placeholder: 'User Email',
	},
	tableSearchUsers: {
		thead: [
			{
				title: 'ID',
				width: '5%',
			},
			{
				title: 'Name',
				width: '60%',
			},
			{
				title: 'Email',
				width: '20%',
			},
			{
				title: 'Add Admin',
				width: '15%',
			},
		],
		tbody: [
			[13, 'Braden Fineberg', 'Test@test.com', 'checkbox'],
			[72, 'IphoneX Test', 'Test@test.com', 'checkbox'],
			[73, 'Androidtest Test', 'Test@test.com', 'checkbox'],
			[308, 'V2.1 Test', 'Test@test.com', 'checkbox'],
			[218, 'Name Surname', 'Test@test.com', 'checkbox'],
		],
	},
	tableSelectedUsers: {
		thead: [
			{
				title: 'ID',
				width: '5%',
			},
			{
				title: 'Name',
				width: '70%',
			},
			{
				title: 'Email',
				width: '25%',
			},
		],
	},
}
