export default [
	{
		value: 1,
		text: "Alabama"
	},
	{
		value: 2,
		text: "Alaska"
	},
	{
		value: 3,
		text: "Arizona"
	},
	{
		value: 4,
		text: "Arkansas"
	},
	{
		value: 5,
		text: "California"
	},
	{
		value: 6,
		text: "Colorado"
	},
	{
		value: 7,
		text: "Connecticut"
	},
	{
		value: 8,
		text: "Delaware"
	},
	{
		value: 9,
		text: "District of Columbia"
	},
	{
		value: 10,
		text: "Florida"
	},
	{
		value: 11,
		text: "Georgia"
	},
	{
		value: 12,
		text: "Hawaii"
	},
	{
		value: 13,
		text: "valueaho"
	},
	{
		value: 14,
		text: "Illinois"
	},
	{
		value: 15,
		text: "Indiana"
	},
	{
		value: 16,
		text: "Iowa"
	},
	{
		value: 17,
		text: "Kansas"
	},
	{
		value: 18,
		text: "Kentucky"
	},
	{
		value: 19,
		text: "Louisiana"
	},
	{
		value: 20,
		text: "Maine"
	},
	{
		value: 21,
		text: "Maryland"
	},
	{
		value: 22,
		text: "Massachusetts"
	},
	{
		value: 23,
		text: "Michigan"
	},
	{
		value: 24,
		text: "Minnesota"
	},
	{
		value: 25,
		text: "Mississippi"
	},
	{
		value: 26,
		text: "Missouri"
	},
	{
		value: 27,
		text: "Montana"
	},
	{
		value: 28,
		text: "Nebraska"
	},
	{
		value: 29,
		text: "Nevada"
	},
	{
		value: 30,
		text: "New Hampshire"
	},
	{
		value: 31,
		text: "New Jersey"
	},
	{
		value: 32,
		text: "New Mexico"
	},
	{
		value: 33,
		text: "New York"
	},
	{
		value: 34,
		text: "North Carolina"
	},
	{
		value: 35,
		text: "North Dakota"
	},
	{
		value: 36,
		text: "Ohio"
	},
	{
		value: 37,
		text: "Oklahoma"
	},
	{
		value: 38,
		text: "Oregon"
	},
	{
		value: 39,
		text: "Pennsylvania"
	},
	{
		value: 40,
		text: "Rhode Island"
	},
	{
		value: 41,
		text: "South Carolina"
	},
	{
		value: 42,
		text: "South Dakota"
	},
	{
		value: 43,
		text: "Tennessee"
	},
	{
		value: 44,
		text: "Texas"
	},
	{
		value: 45,
		text: "Utah"
	},
	{
		value: 46,
		text: "Vermont"
	},
	{
		value: 47,
		text: "Virginia"
	},
	{
		value: 48,
		text: "Washington"
	},
	{
		value: 49,
		text: "West Virginia"
	},
	{
		value: 50,
		text: "Wisconsin"
	},
	{
		value: 51,
		text: "Wyoming"
	}
]
