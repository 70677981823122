/**
 * Reducer which handles all events related to admin actions
 */

// Import action types
import {
	ADD_ORG_SUCCESS,
	ADD_ORG_FAILURE,
	ADD_ORG_REQUEST,
	GET_ORG_CATEGORIES,
	GET_ORG_CATEGORIES_FAIL,
	EDIT_ORG_SUCCESS,
	EDIT_ORG_FAILURE,
	EDIT_ORG_REQUEST,
	EDIT_ORG_VALS,
	EDIT_PROJ_SUCCESS,
	EDIT_PROJ_FAILURE,
	EDIT_PROJ_REQUEST,
	ADD_PROJ_SUCCESS,
	ADD_PROJ_FAILURE,
	ADD_PROJ_REQUEST,
	DELETE_PROJ_SUCCESS,
	DELETE_PROJ_FAILURE,
	DELETE_PROJ_REQUEST,
	ADD_ADMIN_SUCCESS,
	ADD_ADMIN_FAILURE,
	ADD_ADMIN_REQUEST,
	SEARCH_USERS_EMAIL_SUCCESS,
	SEARCH_USERS_EMAIL_REQUEST,
	CLEAR_USERS,
	GET_ORG_REQUEST,
	GET_ORG_SUCCESS,
	GET_ORG_FAILURE,
	CHANGE_SELECTED_PROJECT,
	CLEAR_ORG,
	CLEAR_ERRORS,
	GET_DONATIONS_SUCCESS,
	GET_DONATIONS_FAILURE,
	GET_DONATIONS_REQUEST,
	FETCH_STATISTICS,
	FETCH_STATISTICS_COMPLETE,
	LOGOUT,
} from '../factory/types'

const initialState = {
	users: [],
	donations: [],
	pending: false,
	success: false,
	error: '',
	organization: null,
	orgCategories: [],
}

const adminReducer = (state = initialState, action) => {
	switch (action.type) {
	/*
		Cases for organization management
		*/
	case ADD_ORG_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case ADD_ORG_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case ADD_ORG_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case EDIT_ORG_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case EDIT_ORG_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case EDIT_ORG_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case EDIT_ORG_VALS: {
		const newState = Object.assign({}, state)
		const {target} = action.payload
		if (target.name.split('address_').length > 1) {
			const add = target.name.split('address_').splice(-1)
			newState.organization.address[add] = target.value
		} else {
			newState.organization[target.name] = target.value
		}
		return newState
	}
	case GET_ORG_CATEGORIES: {
		const newState = Object.assign({}, state)
		newState.orgCategories = action.payload
		return newState
	}
	case GET_ORG_CATEGORIES_FAIL: {
		const newState = Object.assign({}, state)
		newState.error = action.payload
		return newState
	}
	case GET_ORG_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case GET_ORG_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		newState.organization = action.organization
		return newState
	}
	case GET_ORG_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	/*
			Cases for project management
			*/
	case EDIT_PROJ_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case EDIT_PROJ_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case EDIT_PROJ_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case DELETE_PROJ_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case DELETE_PROJ_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case DELETE_PROJ_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case ADD_PROJ_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case ADD_PROJ_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case ADD_PROJ_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	/*
			Cases for other admin work
			*/
	case GET_DONATIONS_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.donations = action.donations
		newState.pending = false
		return newState
	}
	case GET_DONATIONS_FAILURE: {
		const newState = Object.assign({}, state)
		newState.donations = []
		newState.pending = false
		newState.error = action.error
		return newState
	}
	case GET_DONATIONS_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case ADD_ADMIN_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case ADD_ADMIN_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case ADD_ADMIN_FAILURE: {
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case SEARCH_USERS_EMAIL_SUCCESS: {
		const newState = Object.assign({}, state)
		newState.users = action.payload
		newState.pending = false
		newState.success = true
		return newState
	}
	case SEARCH_USERS_EMAIL_REQUEST: {
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case CLEAR_USERS: {
		const newState = Object.assign({}, state)
		newState.users = []
		return newState
	}
	/*
			Cases for clearing redux state
			*/
	case CLEAR_ORG: {
		const newState = Object.assign({}, state)
		newState.organization = null
		return newState
	}
	case CLEAR_ERRORS: {
		const newState = Object.assign({}, state)
		newState.error = null
		return newState
	}
	case CHANGE_SELECTED_PROJECT: {
		const newState = Object.assign({}, state)
		newState.selectedProject = action.project
		return newState
	}
	case LOGOUT: {
		const newState = Object.assign({}, state)
		newState.organization = null
		return newState
	}
	default:
		return state
	}
}

export default adminReducer
