import axios from 'axios'

import {getError} from './error'

import {
	GET_ACCOUNTS_SUCCESS,
	GET_ACCOUNTS_FAIL,
	GET_ACCOUNTS_REQUEST,
	EXCHANGE_TOKEN_REQUEST,
	EXCHANGE_TOKEN_SUCCESS,
	EXCHANGE_TOKEN_FAIL,
	USER_ERROR,
	USER_SUCCESS,
	USER_LOADING,
	API,
	TEST_HEADER,
} from './types'

// Action to authorize account during 1-time donations
export const exchangeToken = (otp_token, key) =>  (dispatch) => {
	axios.post(`${API}/v1/user/exchange_otp_token`, {otp_token, key}, {
		headers: {
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	})
		.then((res) => {
			dispatch({
				type: EXCHANGE_TOKEN_SUCCESS,
				payload: `Bearer ${res.data.data}`
			})
		})
		.catch((err) => {
			console.log(getError(err))
		})
}

export const getAccounts = (token) => (dispatch) => {
	dispatch({type: GET_ACCOUNTS_REQUEST})

	axios.get(`${API}/v1/account/list?is_linked=true`, { headers: {
		authorization: token,
		'flourish-test-header': TEST_HEADER
	}})
		.then((res) => {
			dispatch({
				type: GET_ACCOUNTS_SUCCESS,
				payload: res.data.data
			})
		})
		.catch((err) => {
			dispatch({type: GET_ACCOUNTS_FAIL})
			console.log(err)
		})
}

export const unauthConfigDonations = (otp_token, key) => (dispatch) => {
	dispatch({type: EXCHANGE_TOKEN_REQUEST})
	axios.post(`${API}/v1/user/exchange_otp_token`, {otp_token, key}, {
		headers: {
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	})
		.then((res) => {
			const authToken = `Bearer ${res.data.data}`
			dispatch({
				type: EXCHANGE_TOKEN_SUCCESS,
				payload: authToken
			})
			return authToken
		})
		.then((token) => dispatch(getAccounts(token)))
		.catch((err) => {
			dispatch({type: EXCHANGE_TOKEN_FAIL})
			console.log(err)
			console.log(getError(err))
		})
}

export const setDonationAccount = (account_id, token) => () => {
	axios.post(`${API}/v1/account/set_is_payment`, {
		account_id,
		"is_payment": true}, {
		headers: {
			authorization: token,
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	})
}

export const setDonationType = (params, token) => () => {
	axios.post(`${API}/v1/web/user/set_donor_type`, params, {
		headers: {
			authorization: token,
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	})
}

export const unsubscribe = ({code}) => (dispatch) => {
	dispatch({type: USER_LOADING})
	axios.get(`${API}/v1/web/user/email_unsubscribe?code=${code}`, {
		headers: {
			'Content-Type': 'application/json',
			'flourish-test-header': TEST_HEADER
		}
	})
		.then(()=>dispatch({type: USER_SUCCESS, payload: 'unsubscribe'}))
		.catch((err) => {
			dispatch({type: USER_ERROR, payload: getError(err)})
		})
}
