/**
 * Reducer which handles all events related to user authentication process
 */

// Import action types
import {
	GET_ORGS_REQUEST,
	GET_ORGS_SUCCESS,
	GET_ORGS_FAILURE,
	GET_PROJECTS_REQUEST,
	GET_PROJECTS_SUCCESS,
	GET_PROJECTS_FAILURE,
	SELECT_ORG,
	SELECT_PROJ,
	SELECT_PROJECTS,
	SET_ORG_SUCCESS,
	SET_ORG_FAILURE,
	SET_ORG_REQUEST,
	SET_PROJ_SUCCESS,
	SET_PROJ_FAILURE,
	SET_PROJ_REQUEST,
	SET_DAILY_DONATION_FAILURE,
	SET_DAILY_DONATION_SUCCESS,
	SET_DAILY_DONATION_REQUEST,
	CLEAR_ORG_AND_PROJ,
	CLEAR_PROJECTS,
	CLEAR_SELECTED_PROJ,
	CLEAR_ERRORS,
	DONATE_NOW_SUCCESS,
	DONATE_NOW_FAILURE,
	DONATE_NOW_REQUEST,
	ONE_TIME_DONATION_AUTHED_FAILURE,
	ONE_TIME_DONATION_AUTHED_SUCCESS,
	ONE_TIME_DONATION_AUTHED_REQUEST,
	LINK_BANK_SUCCESS,
	LINK_BANK_FAILURE,
	LINK_BANK_REQUEST,
	LINK_CREDIT_CARD_SUCCESS,
	LINK_CREDIT_CARD_FAILURE,
	LINK_CREDIT_CARD_REQUEST,
	CLEAR_DONATE_NOW,
} from '../factory/types'

const initialState = {
	organizations: [],
	pending: false,
	error: null,
	success: false,
	projects: []
}


const donateNowReducer = (state = initialState, action) => {
	switch (action.type) {
	case GET_ORGS_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case GET_ORGS_SUCCESS:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		newState.organizations = action.organizations
		return newState
	}
	case GET_ORGS_FAILURE:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case GET_PROJECTS_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case GET_PROJECTS_SUCCESS:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		newState.projects = action.projects
		return newState
	}
	case GET_PROJECTS_FAILURE:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	/*
			Cases for selecting
			*/
	case SELECT_ORG:
	{
		const newState = Object.assign({}, state)
		newState.selectedOrg = action.org
		return newState
	}
	case SELECT_PROJ:
	{
		const newState = Object.assign({}, state)
		newState.selectedProj = action.proj
		return newState
	}
	case SELECT_PROJECTS:
	{
		const newState = Object.assign({}, state)
		newState.selectedProjects = action.projects
		return newState
	}
	case SET_ORG_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case SET_ORG_SUCCESS:
	{
		const newState = Object.assign({}, state)
		newState.error = null
		newState.success = true
		newState.pending = false
		return newState
	}
	case SET_ORG_FAILURE:
	{
		const newState = Object.assign({}, state)
		newState.error = action.error
		newState.pending = false
		newState.success = false
		return newState
	}
	case SET_PROJ_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case SET_PROJ_SUCCESS:
	{
		const newState = Object.assign({}, state)
		newState.error = null
		newState.success = true
		newState.pending = false
		return newState
	}
	case SET_PROJ_FAILURE:
	{
		const newState = Object.assign({}, state)
		newState.error = action.error
		newState.pending = false
		newState.success = false
		return newState
	}
	/*
			Cases for donating
			*/
	case DONATE_NOW_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case DONATE_NOW_SUCCESS:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case DONATE_NOW_FAILURE:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case ONE_TIME_DONATION_AUTHED_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case ONE_TIME_DONATION_AUTHED_SUCCESS:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = true
		newState.error = null
		return newState
	}
	case ONE_TIME_DONATION_AUTHED_FAILURE:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = false
		newState.error = action.error
		return newState
	}
	case SET_DAILY_DONATION_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case SET_DAILY_DONATION_FAILURE:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.error
		newState.success = false
		return newState
	}
	case SET_DAILY_DONATION_SUCCESS:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = null
		newState.success = true
		return newState
	}
	case LINK_BANK_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case LINK_BANK_SUCCESS:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = null
		newState.success = true
		return newState
	}
	case LINK_BANK_FAILURE:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.error
		newState.success = false
		return newState
	}
	case LINK_CREDIT_CARD_REQUEST:
	{
		const newState = Object.assign({}, state)
		newState.pending = true
		return newState
	}
	case LINK_CREDIT_CARD_SUCCESS:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = null
		newState.success = true
		newState.redirectToConfirmation = true
		return newState
	}
	case LINK_CREDIT_CARD_FAILURE:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.error = action.error
		newState.success = false
		return newState
	}
	/*
			Cases for clearing state
			*/
	case CLEAR_DONATE_NOW:
	{
		const newState = Object.assign({}, state)
		newState.pending = false
		newState.success = null
		newState.selectedProj = null
		newState.selectedOrg = null
		newState.projects = null
		newState.organizations = null
		newState.error = null
		newState.redirectToConfirmation = null
		return newState
	}
	case CLEAR_ORG_AND_PROJ:
	{
		const newState = Object.assign({}, state)
		newState.selectedProj = null
		newState.selectedOrg = null
		return newState
	}
	case CLEAR_PROJECTS:
	{
		const newState = Object.assign({}, state)
		newState.projects = null
		return newState
	}
	case CLEAR_SELECTED_PROJ:
	{
		const newState = Object.assign({}, state)
		newState.selectedProj = null
		return newState
	}
	case CLEAR_ERRORS:
	{
		const newState = Object.assign({}, state)
		newState.error = null
		return newState
	}
	default:
		return state
	}
}

export default donateNowReducer
