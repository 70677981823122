import axios from 'axios'

import {
	COMPLETE_TASK_REQUEST,
	COMPLETE_TASK_SUCCESS,
	COMPLETE_TASK_FAIL,
	GET_TASKS_SUCCESS
} from './types'


export const updateTask = (task_id, value, token) => (dispatch) => {
	dispatch({
		type: COMPLETE_TASK_REQUEST,
	})

	dispatch({
		type: COMPLETE_TASK_SUCCESS,
		payload: value
	})
}

export const getTasks = (taskList) => (dispatch) => {
	dispatch({
		type: GET_TASKS_SUCCESS,
		payload: taskList
	})
}
