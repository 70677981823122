import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'

import rootReducer from '../reducers'
import { STAGING } from '../factory/types'

let store = null

export default () => {
	const staging = STAGING === 'true'

	if (staging) {
		store = createStore(
			rootReducer,
			undefined,
			applyMiddleware(thunk, logger)
		)
	} else {
		store = createStore(rootReducer, undefined, applyMiddleware(thunk))
	}

	return store
}
